import router from '@/router'
import { removeAllEventSocket } from '@/utils/websocket'
import { authenticationService, HttpStatus } from '@/services/auth.service'

const login = async ({ commit }, payload) => {
    commit('loginRequest')

    try {
        const result = await authenticationService.login(payload.email, payload.password)
        return result
    } catch (error) {
        if (error instanceof HttpStatus) {
            commit('loginError', {statusCode: error.statusCode, errorMessage: error.message.message})
        }
        return false
    }
}

const forgotPassword = async ({ commit }, payload) => {
    try {
        await authenticationService.forgotPassword(payload)
        commit('ActionSuccess')
    } catch (error) {
        if (error instanceof HttpStatus) {
            commit('loginError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const changePassword = async ({ commit }, data) => {
    try {
        await authenticationService.changePassword(data)
        commit('ActionSuccess')
    } catch (error) {
        if (error instanceof HttpStatus) {
            commit('loginError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const changePasswordFromForgot = async ({ commit }, data) => {
    try {
        await authenticationService.changePasswordFromForgot(data)
        commit('ActionSuccess')
    } catch (error) {
        if (error instanceof HttpStatus) {
            commit('loginError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const changePasswordFromProfile = async ({ commit }, data) => {
    try {
        const result = await authenticationService.changePasswordFromProfile(data)
        return result
    } catch (error) {
        if (error instanceof HttpStatus) {
            commit('loginError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const logout = ({ commit }) => {
    authenticationService.logout()
    commit('logoutSuccess')
    removeAllEventSocket()
    router.push('/login')
}

export default {
    login,
    logout,
    forgotPassword,
    changePassword,
    changePasswordFromForgot,
    changePasswordFromProfile,
}