const getProfileUser = (state) => {
    return {
        profile_picture: state.profile_picture,
        preview_image: state.preview_image,
        namePic: state.namePic,
        emailPic: state.emailPic,
        noPic: state.noPic,
        rolePic: state.rolePic,
        canAddAddress: state.can_add_address
    }
}

const getHttpCode = state => state.httpCode
const getHttpMessage = state => state.httpMessage


export default {
    getProfileUser,
    getHttpMessage,
    getHttpCode,
}