import Vue from 'vue'
import store from '@/store'
import Router from 'vue-router'
import { DefaultPassword } from '../services/storage.service'

Vue.use(Router)

import Layout from '@/layout'

export const constantRoutes = [
    {
        path: '/login',
        name: 'TheLogin',
        component: () => import('@/views/authentication'),
        hidden: true,
        meta: {
            onlyWhenLoggedOut: true
        }
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: () => import('@/views/forgot-password'),
        hidden: true,
        meta: {
            onlyWhenLoggedOut: true
        }
    },
    {
        path: '/forgot-password-profile',
        name: 'ForgotPasswordProfile',
        component: () => import('@/views/forgot-password'),
        hidden: true,
    },
    {
        path: '/change-password',
        name: 'ChangePassword',
        component: () => import('@/views/change-password'),
        hidden: true,
        meta: {
            onlyWhenLoggedOut: true
        }
    },
    {
        path: '/password-change',
        name: 'passwordChange',
        component: () => import('@/views/change-password'),
        hidden: true,
    },
    {
        path: '/',
        component: Layout,
        beforeEnter: (to, from, next) => {
            const hasDefaultPass = DefaultPassword.getFlagPass()
            if (hasDefaultPass === '1') {
                next('/password-change')
            } else {
                next()
            }
        },
        redirect: '/dashboard',
        children: [{
            path: 'dashboard',
            name: 'TheDashboard',
            component: () => import('@/views/dashboard/index'),
            meta: { title: 'Dashboard' }
        }],
    },
    {
        path: '/project',
        component: Layout,
        redirect: '/project',
        name: 'TheProject',
        meta: { title: 'Project Index' },
        children: [
          {
            path: '',
            component: () => import('@/views/project/index'),
            meta: { title: 'Project' },
            children: [
                {
                    path: '',
                    redirect: '/project/survey'
                },
                {
                    path: '/project/survey',
                    name: 'TheSurvey',
                    component: () => import('@/views/project/survey-project/index'),
                    beforeEnter(to, from, next) {
                        if (!to.name) {
                            next(false)
                        } else {
                            const payload = {
                                search_by: 'store',
                                page: 1,
                                limit: 10
                            }
                            store.dispatch("project/getTabsChildProject");
                            store.dispatch('project/listProjectSurvey', payload)
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                            next()
                        }
                    }
                },
                {
                    path: '/project/offering',
                    name: 'TheOffer',
                    component: () => import('@/views/project/offer-project/index'),
                    beforeEnter(to, from, next) {
                        if (!to.name) {
                            next(false)
                        } else {
                            const payload = {
                                search_by: 'store',
                                page: 1,
                                limit: 10
                            }
                            store.dispatch('project/getTabsChildOffer')
                            store.dispatch('project/listProjectOffer', payload)
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                            next()
                        }
                    }
                },
                {
                    path: '/project/project-active',
                    name: 'IndexProjectProgress',
                    component: () => import('@/views/project/inprogress-done-project/IndexProjectProgress')
                },
                {
                    path: '/project/project-complete',
                    name: 'IndexProjectDone',
                    component: () => import('@/views/project/inprogress-done-project/IndexProjectDone')
                },
                {
                    path: '/project/unpaid-bills',
                    name: 'TagihanIndex',
                    component: () => import('@/views/project/tagihan-project/index')
                },
                {
                    path: '/project/paid-bills',
                    name: 'IndexProjectPaid',
                    component: () => import('@/views/project/paid-project/Index')
                },
            ]
          },
          {
            path: '/project/survey/create',
            component: () => import('@/views/project/survey-project/create'),
            meta: { title: 'Project Survey Create' },
            beforeEnter(to, from, next) {
                if (!to.name) {
                    next(false)
                }

                try {
                    store.dispatch("branch/branchAll").then((response) => {
                        if (response.data.code === 1) {
                            store.commit("project/setAddressAll", response.data.data.data);
                            store.commit("project/setAddressLatest", response.data.data.latest);
                        }
                    });
                    next();
                } catch (error) {
                    console.error("Error Fetching data:", error);
                    next(false)
                }
            },
          },
          {
            path: 'detail/:id',
            name: 'detailProject',
            component: () => import('@/views/project/survey-project/detail'),
            meta: { title: 'Detail Project' }
          },
          {
            path: 'project-main-detail/:id',
            name: 'DetailMainProject',
            component: () => import('@/views/project/DetailMainProject'),
            meta: { title: 'Detail Main Project' }
          }
        ]
    },
    {
        path: '/invoice',
        name: 'InvoicePreviewFile',
        component: () => import('@/views/print/InvoicePreviewFile'),
        meta: { title: 'Cetak Invoice Project' }
    },
    {
        path: '/penawaran',
        name: 'OfferingPreviewFile',
        component: () => import('@/views/print/OfferingPreviewFile'), // change to new view
        meta: { title: 'Cetak Penawaran Project' }
    },
    {
        path: '/penawaran/lampiran',
        name: 'AttachmentProject',
        component: () => import('@/views/print/AttachmentProject'),
        meta: { title: 'Cetak Lampiran Penawaran Project' }
    },
    {
        path: '/laporan',
        component: Layout,
        redirect: '/laporan',
        name: 'TheLaporan',
        meta: { title: 'Laporan Index' },
        children: [
            {
                path: '',
                component: () => import('@/views/laporan/index'),
                meta: { title: 'Laporan' }
            }
        ]
    },
    {
        path: '/jadwal',
        component: Layout,
        redirect: '/jadwal',
        name: 'TheSchedule',
        meta: { title: 'Jadwal Survey' },
        children: [
            {
                path: '',
                component: () => import('@/views/schedule/index'),
                meta: { title: 'Jadwal Survey' }
            }
        ]
    },
    {
        path: '/pengaturan',
        component: Layout,
        redirect: '/pengaturan',
        name: 'ThePengaturan',
        meta: { title: 'Pengaturan Index' },
        children: [
            {
                path: '',
                component: () => import('@/views/settings/index'),
                meta: { title: 'Pengaturan' }
            }
        ]
    },
    {
        path: '/profile',
        component: Layout,
        redirect: '/profile',
        name: 'TheProfile',
        meta: { title: 'Profile' },
        children: [
          {
            path: '',
            component: () => import('@/views/profile/index'),
            meta: { title: 'Profile' }
          },
        ]
    },
    {
        path: '/branch-store',
        component: Layout,
        redirect: '/branch-store',
        name: 'BranchStoreIndex',
        meta: { title: 'Branch Store' },
        children: [
          {
            path: '',
            component: () => import('@/views/branch-store/index'),
            meta: { title: 'Branch Store' }
          },
          {
            path: '/branch-store/detail/:id',
            name: 'detailStore',
            component: () => import('@/views/branch-store/detailStore'),
            meta: { title: 'Detail Branch Store' }
          },
        ]
    },
    {
        path: '/all-notification',
        component: Layout,
        redirect: '/all-notification',
        name: 'TheNotification',
        meta: { title: 'All Notification' },
        children: [
          {
            path: '',
            component: () => import('@/views/notification/index'),
            meta: { title: 'All Notification' }
          },
        ]
    },
    {
        path: '*',
        redirect: '/404',
        hidden: true
    },
    {
        path: '/404',
        component: () => import('@/views/NotFound'),
        hidden: true
    },
]

const createRouter = () => new Router({
    scrollBehavior: (to, from, savedPosition) => {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 };
        }
    },
    routes: constantRoutes,
    linkActiveClass: 'is-active'
})

const router = createRouter()

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher
}

export default router

