const getSubStatus = state => state.sub_status

// ERROR RES
const getResponseErrorCode = state => state.responseErrorCode
const getResponseErrorMessage = state => state.responseErrorMessage


export default {
    getSubStatus,
    getResponseErrorCode,
    getResponseErrorMessage
}