import ApiService from './api.service'
class HttpResponse extends Error {
    constructor(code, message) {
        super(message)
        this.message = message
        this.statusCode = code
    }
}

const scheduleServices = {
    scheduleProjectApi: async function(payload) {
        try {
            const requestData = {
                method: 'GET',
                url: `${process.env.VUE_APP_SCHEDULE}`,
                params: payload,
            }
            const result = await ApiService.customRequest(requestData)
            return result
        } catch (error) {
            throw new HttpResponse(error.response.status, error.response.data)
        }
    },
    branchListApi: async function() {
        try {
            const requestData = {
                method: 'GET',
                url: `${process.env.VUE_APP_BRANCHES}`,
            }
            const result = await ApiService.customRequest(requestData)
            return result
        } catch (error) {
            throw new HttpResponse(error.response.status, error.response.data)
        }
    }
}

export default scheduleServices

export {
    HttpResponse,
    scheduleServices,
}