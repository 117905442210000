import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
    profile_picture : null,
    preview_image : null,
    namePic : null,
    emailPic : null,
    noPic : null,
    rolePic : null,
    can_add_address: null,
    httpCode: 0,
    httpMessage: ''
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}