const calendarData = (state, data) => {
    state.event_data = data
}

const startSelectedRange = (state, data) => {
    state.start_date_selected = data
}

const endSelectedRange = (state, data) => {
    state.end_date_selected = data
}

const listBranchStore = (state, data) => {
    state.branch_store = data
}

const responseSuccess = (state, {statusCode, message }) => {
    state.httpCode = statusCode
    state.httpMessage = message
}

const responseError = (state, {statusCode, message}) => {
    state.httpCode = statusCode
    state.httpMessage = message
}

const defaultState = (state) => {
    state.event_data = []
    state.branch_store = []
    state.end_date_selected = null
    state.start_date_selected = null
    state.httpCode = 0,
    state.httpMessage = ''
}

export default {
    startSelectedRange,
    endSelectedRange,
    listBranchStore,
    responseSuccess,
    responseError,
    calendarData,
    defaultState
}