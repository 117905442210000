const getHttpCode = state => state.httpCode
const getHttpMessage = state => state.httpMessage

const getDataCalendar = state => state.event_data
const getEndDate = state => state.end_date_selected
const getListBranchStore = state => state.branch_store
const getStartDate = state => state.start_date_selected


export default {
    getListBranchStore,
    getDataCalendar,
    getHttpMessage,
    getStartDate,
    getHttpCode,
    getEndDate,
}