import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
    summary: null,
    unread_count: null,
    notification_preview: [],
    notification_all: [],
    responseError: '',
    responseErrorCode: 0,
    user_interacted: false,
    heightNavbar: null,
    current_page: 1,
    total_pages: 0,
    limit_page: 50,
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}