const setAddress = (state, {address_note, branch_id, address, nameBranch}) => {
    state.address = address
    state.nameBranch = nameBranch
    state.address_note = address_note
    state.branch_id = branch_id
}

const setPicBranch = (state, data) => {
    state.pic_branch = data
}

const setPhotoProblem = (state, data) => {
    state.photo_problem = data
}

const setSpliceImageProblem = (state, index) => {
    state.photo_problem.splice(index, 1)
}

const setPicId = (state, {idPic, namePic, phonePic}) => {
    state.pic_id = idPic
    state.name_pic = namePic
    state.phone_pic = phonePic
}

const setDescription = (state, description) => {
    state.project_description = description
}

const setDateProject = (state, date) => {
    state.date_sesi = date
}

const setDetailSurvey = (state, data) => {
    state.detailsSurvey = data
    state.status_offer = data?.offering?.status
}

const setFileSpk = (state, data) => {
    state.file_spk = data
}

const setFileSik = (state, data) => {
    state.file_sik = data
}

const setNoteConfirm = (state, data) => {
    state.note_confirm = data
}

const setDateConfirm = (state, data) => {
    state.date_confirm_offer = data
}

const setOfferNumber = (state, data) => {
    state.offering_number = data
}

const setDetailProjectMain = (state, data) => {
    state.detail_main_project = data
}

const setDurationPreparation = (state, data) => {
    state.duration_preparation = data
}

const setAddressAll = (state, data) => {
    state.address_all = data
}

const setAddressLatest = (state, data) => {
    state.address_latest = data
}

const setListSurvey = (state, value) => {
    if (value) {
        state.current_page = value.current_page
        state.total_pages = value.total_page
        state.list_survey = value.data
    }
}

const setListOffer = (state, value) => {
    if (value) {
        state.current_page = value.current_page
        state.total_pages = value.total_page
        state.list_offer = value.data
    }
}

const setTabFilterSurvey = (state, value) => {
    if (value) {
        state.tab_status_survey = value
    }
}

const setTabFilterOffer = (state, value) => {
    if (value) {
        state.tab_status_offer = value
    }
}

const setDateHoliday = (state, value) => {
    if (value) {
        const dateHoliday = value.type === 'survey' ? state.date_holiday : state.date_holiday_project;
        const newData = {
            'start': value.start_date,
            'end': value.end_date
        };

        dateHoliday.push(newData);

        const resultData = dateHoliday.reduce((acc, current) => {
            const x = acc.find(item => item.end_date === current.end_date && item.start_date === current.start_date);
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []);

        if (value.type === 'survey') {
            state.date_holiday = resultData;
        } else {
            state.date_holiday_project = resultData;
        }
    }
}

const setShowTourAttachment = (state, value) => {
    if (value) {
        state.tour_attachment_show = value
    }
}


// RESET STATE
const defaultState = (state) => {
    state.branch_id = null
    state.name_pic = null
    state.phone_pic = null
    state.pic_id = null
    state.building_type_id = ''
    state.date_sesi = ''
    state.project_description = null
    state.address_note = null
    state.address = null
    state.nameBranch = null
    state.address_all = null
    state.address_latest = null
    state.pic_branch = []
    state.photo_problem = []
    state.responseErrorCode = 0
    state.responseErrorMessage = ''
}

const stateDefaultFileSpk = (state) => {
    state.file_spk = null
}

const stateDefaultFileSik = (state) => {
    state.file_sik = null
}

const resetFormConfirmOffer = (state) => {
    state.file_spk = null
    state.file_sik = null
    state.date_confirm_offer = null
    state.note_confirm = null
}

const defaultStateDetail = (state) => {
    state.detailsSurvey = null
    state.offering_number = null
    state.duration_preparation = null
}

const defaultStateDetailMainProject = (state) => {
    state.detail_main_project = null
}

const defaultAddressSelected = (state) => {
    state.address = null
    state.nameBranch = null
    state.address_note = null
    state.branch_id = null
}

const defaultStateRes = (state) => {
    state.responseErrorCode = 0
    state.responseErrorMessage = ''
}

const resetAll = (state) => {
    state.branch_id= null
    state.name_pic= null
    state.phone_pic= null
    state.pic_id= null
    state.pic_branch= []
    state.photo_problem = []
    state.building_type_id= ''
    state.date_sesi= ''
    state.project_description= null
    state.address_note= null
    state.address= null
    state.nameBranch= null
    state.detailsSurvey= null
    state.file_spk= null
    state.file_sik= null
    state.note_confirm= null
    state.date_confirm_offer= null
    state.offering_number= null
    state.detail_main_project= null
    state.duration_preparation= null
    state.address_all= null
    state.address_latest= null
    state.list_survey= []
    state.list_offer= []
    state.status_offer = null
    state.tab_status_survey= []
    state.tab_status_offer= []
    state.date_holiday= []
    state.tour_attachment_show = null
    state.responseErrorCode= 0
    state.responseErrorMessage= ''
}
// END RESET STATE

const responsesError = (state, {errorCode, errorMessage}) => {
    state.responseErrorCode = errorCode
    state.responseErrorMessage = errorMessage
}

export default {
    setPicId,
    resetAll,
    setFileSpk,
    setFileSik,
    setAddress,
    setListOffer,
    setPicBranch,
    defaultState,
    setAddressAll,
    setListSurvey,
    setOfferNumber,
    setDateHoliday,
    setDateConfirm,
    setDescription,
    responsesError,
    setDateProject,
    setNoteConfirm,
    defaultStateRes,
    setDetailSurvey,
    setPhotoProblem,
    setAddressLatest,
    setTabFilterOffer,
    setTabFilterSurvey,
    defaultStateDetail,
    stateDefaultFileSpk,
    stateDefaultFileSik,
    setDetailProjectMain,
    setSpliceImageProblem,
    setShowTourAttachment,
    resetFormConfirmOffer,
    setDurationPreparation,
    defaultAddressSelected,
    defaultStateDetailMainProject
}