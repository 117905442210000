import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
    list_store: null,
    detail_store: null,
    responseErrorCode: 0,
    responseErrorMessage: '',
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}