import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
    event_data: [],
    branch_store: [],
    end_date_selected: null,
    start_date_selected: null,
    httpCode: 0,
    httpMessage: ''
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}