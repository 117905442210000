<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters("dashboard", ["getUserInteracted"]),
  },
  watch: {
    getUserInteracted(val) {
      if (val === true) {
        document.removeEventListener("click", this.onUserInteracted);
        document.removeEventListener("keydown", this.onUserInteracted);
      }
    },
  },
  methods: {
    onUserInteracted() {
      if (!this.getUserInteracted) {
        this.$store.commit("dashboard/setUserInteracted", true);
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.onUserInteracted);
    document.addEventListener("keydown", this.onUserInteracted);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.onUserInteracted);
    document.removeEventListener("keydown", this.onUserInteracted);
  },
};
</script>

<style>

@font-face {
  font-family: "Averta";
  src: local("Averta"), url("./fonts/Averta-Regular.otf") format("truetype");
}

@font-face {
  font-family: "Averta-Bold";
  src: url("./fonts/Averta-Bold.otf") format("truetype"),
      url('./fonts/Averta-Bold.otf?#iefix') format('embedded-opentype'),
      url('./fonts/Averta-Bold.otf') format('woff'),
      url('./fonts/Averta-Bold.otf') format('truetype'),
      url('./fonts/Averta-Bold.otf#rsuregular') format('svg');
}

@font-face {
  font-family: "Averta-Extra-Bold";
  src: url("./fonts/Averta-Extra-Bold.otf") format("truetype"),
      url('./fonts/Averta-Extra-Bold.otf?#iefix') format('embedded-opentype'),
      url('./fonts/Averta-Extra-Bold.otf') format('woff'),
      url('./fonts/Averta-Extra-Bold.otf') format('truetype'),
      url('./fonts/Averta-Extra-Bold.otf#rsuregular') format('svg');
}

@font-face {
  font-family: "Averta-Bold-Italic";
  src: url("./fonts/Averta-Bold-Italic.otf") format("truetype"),
      url('./fonts/Averta-Bold-Italic.otf?#iefix') format('embedded-opentype'),
      url('./fonts/Averta-Bold-Italic.otf') format('woff'),
      url('./fonts/Averta-Bold-Italic.otf') format('truetype'),
      url('./fonts/Averta-Bold-Italic.otf#rsuregular') format('svg');
}

@font-face {
  font-family: "Averta-Reguler-Italic";
  src: url("./fonts/Averta-Regular-Italic.otf") format("truetype"),
      url('./fonts/Averta-Regular-Italic.otf?#iefix') format('embedded-opentype'),
      url('./fonts/Averta-Regular-Italic.otf') format('woff'),
      url('./fonts/Averta-Regular-Italic.otf') format('truetype'),
      url('./fonts/Averta-Regular-Italic.otf#rsuregular') format('svg');
}

@font-face {
  font-family: "Averta-Black";
  src: url("./fonts/Averta-Black.otf") format("truetype"),
      url('./fonts/Averta-Black.otf?#iefix') format('embedded-opentype'),
      url('./fonts/Averta-Black.otf') format('woff'),
      url('./fonts/Averta-Black.otf') format('truetype'),
      url('./fonts/Averta-Black.otf#rsuregular') format('svg');
}

@font-face {
  font-family: "Averta-Semibold";
  src: url("./fonts/Averta-Semibold.otf") format("truetype"),
      url('./fonts/Averta-Semibold.otf?#iefix') format('embedded-opentype'),
      url('./fonts/Averta-Semibold.otf') format('woff'),
      url('./fonts/Averta-Semibold.otf') format('truetype'),
      url('./fonts/Averta-Semibold.otf#rsuregular') format('svg');
}

@font-face {
  font-family: "Averta-Light";
  src: url("./fonts/Averta-Light.otf") format("truetype"),
      url('./fonts/Averta-Light.otf?#iefix') format('embedded-opentype'),
      url('./fonts/Averta-Light.otf') format('woff'),
      url('./fonts/Averta-Light.otf') format('truetype'),
      url('./fonts/Averta-Light.otf#rsuregular') format('svg');
}

.b-tabs .tabs .is-active {
  font-family: "Averta-Bold";
}

.b-tabs .tabs .is-active .tag {
  color: white !important;
  background: #d9272d;
}

#app {
  font-family: Averta, Helvetica, Arial, sans-serif;
}

@import url("./assets/css/main.css");
</style>
