const setDetailStore = (state, data) => {
    state.detail_store = data
}

const setListStore = (state, data) => {
    state.list_store = data
}

// RESET STATE
const defaultState = (state) => {
    state.detail_store = null
    state.list_store = null
    state.responseErrorCode = 0
    state.responseErrorMessage = ''
}
// END RESET STATE

// RESPONSE ERROR
const responsesError = (state, {errorCode, errorMessage}) => {
    state.responseErrorCode = errorCode
    state.responseErrorMessage = errorMessage
}

export default {
    setListStore,
    defaultState,
    responsesError,
    setDetailStore,
}