import ApiService from './api.service'

class ResponseError extends Error {
    constructor(errorCode, message) {
        super(message)
        this.message = message
        this.errorCode = errorCode
    }
}

const billingService = {
    billOfListAPI: async function(payload) {
        try {
            const requestData = {
                method: 'GET',
                url: `${process.env.VUE_APP_STORE_LIST_BILL}`,
                params: payload,
            }
            const result = await ApiService.customRequest(requestData)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    billOfListAndFailedAPI: async function(payload) {
        try {
            const requestData = {
                method: 'GET',
                url: `${process.env.VUE_APP_STORE_LIST_BILL}/project`,
                params: payload,
            }
            const result = await ApiService.customRequest(requestData)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    priceCalculationAPI: async function(payload) {
        try {
            const url = `${process.env.VUE_APP_STORE_LIST_BILL}/price-calculation`
            const result = await ApiService.post(url, payload)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    payBillAPI: async function(payload) {
        try {
            const url = process.env.VUE_APP_STORE_PAY_BILL
            const result = await ApiService.post(url, payload)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    billingDetailListAPI: async function(pathVariable) {
        try {
            const url = `${process.env.VUE_APP_STORE_LIST_BILL}/${pathVariable}/detail`
            const result = await ApiService.get(url)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    }
}

export default billingService

export {
    billingService,
    ResponseError
}