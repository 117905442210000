const loggedIn = state => state.accessToken ? true : false

const authenticationErrorCode = state => state.authenticationErrorCode

const authenticationError = state => state.authenticationError

const authenticating = state => state.authenticating

export default {
    loggedIn,
    authenticationErrorCode,
    authenticationError,
    authenticating,
}