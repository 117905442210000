import { userService, HttpResponse } from '@/services/user.service'

const detailProfile = async ({ commit }) => {
    try {
        const result = await userService.detailProfileApi()
        commit('setDetailUser', result.data?.data)
        return result
    } catch (error) {
        if (error instanceof HttpResponse) {
            commit('responseError', {statusCode: error.statusCode, message: error.message.message})
        }
        return false
    }
}

const updateProfile = async ({ commit }, payload) => {
    try {
        const newObj = {};
        for (const key in payload) {
          if (payload[key] !== null) {
            newObj[key] = payload[key];
          }
        }

        const formData = new FormData()
        formData.append('name', newObj.name)
        if (newObj.image && newObj.image !== null) {
            formData.append('image', newObj.image)
        }

        const result = await userService.updateProfileApi(formData)
        commit('responseSuccess', {statusCode: result.status, message: result.data.message})
        return result
    } catch (error) {
        if (error instanceof HttpResponse) {
            commit('responseError', {statusCode: error.statusCode, message: error.message.message})
        }
        return false
    }
}

const resetAll = ({ commit }) => {
    commit('defaultState')
}

export default {
    updateProfile,
    detailProfile,
    resetAll
}