import ApiService from './api.service'
class HttpResponse extends Error {
    constructor(code, message) {
        super(message)
        this.message = message
        this.statusCode = code
    }
}

const userService = {
    detailProfileApi: async function() {
        try {
            const url = process.env.VUE_APP_USER_DATA
            const result = await ApiService.get(url)
            return result
        } catch (error) {
            throw new HttpResponse(error.response.status, error.response.data)
        }
    },
    updateProfileApi: async function(payload) {
        try {
            const requestData = {
                method: 'POST',
                url: `${process.env.VUE_APP_UPDATE_USER_PROFILE}`,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data: payload,
            }
            const result = await ApiService.customRequest(requestData)
            return result
        } catch (error) {
            throw new HttpResponse(error.response.status, error.response.data)
        }
    }
}

export default userService

export {
    HttpResponse,
    userService,
}