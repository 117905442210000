import Vue from 'vue'
import Vuex from 'vuex'
import authModule from './modules/auth'
import userModule from './modules/user'
import branchModule from './modules/branch'
import projectModule from './modules/project'
import billingModule from './modules/billing'
import dashboardModule from './modules/dashboard'
import scheduleModule from './modules/schedule'


Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth: authModule,
        user: userModule,
        branch: branchModule,
        project: projectModule,
        billing: billingModule,
        dashboard: dashboardModule,
        schedule: scheduleModule
    }
})