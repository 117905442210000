// import { calendarSchedule } from '@/services/storage.service'
import { scheduleServices, HttpResponse } from '@/services/schedule.service'

const scheduleProject = async ({ commit }, payload) => {
    try {
        const result = await scheduleServices.scheduleProjectApi(payload)

        commit('calendarData', result.data.data?.calendar)
        commit('startSelectedRange', payload.start_date)
        commit('endSelectedRange', payload.end_date)

        return result
    } catch (error) {
        if (error instanceof HttpResponse) {
            commit('responseError', {statusCode: error.statusCode, message: error.message.message})
        }
        return false
    }
}

const branchOutlet = async ({ commit }) => {
    try {
        const result = await scheduleServices.branchListApi()
        const arrData = result.data.data?.data;

        arrData.map((e) => (commit('listBranchStore', e.stores)));

        return true
    } catch (error) {
        if (error instanceof HttpResponse) {
            commit('responseError', {statusCode: error.statusCode, message: error.message.message})
        }
        return false
    }
}


const resetAll = ({ commit }) => {
    commit('defaultState')
}

export default {
    scheduleProject,
    branchOutlet,
    resetAll
}