const getDetailStore = state => state.detail_store
const getListStore = state => state.list_store

// ERROR RESPONSES
const getResponseErrorCode = state => state.responseErrorCode
const getResponseErrorMessage = state => state.responseErrorMessage

export default {
    getListStore,
    getDetailStore,
    getResponseErrorCode,
    getResponseErrorMessage
}