import Vue from 'vue'
import Buefy from 'buefy'
import App from './App.vue'
import store from './store'
import moment from 'moment'
import router from './router'
import GmapVue from 'gmap-vue'
import VCalendar from 'v-calendar'
import DOMPurify from 'dompurify'
import Geocoder from '@pderas/vue2-geocoder'
import VueSweetalert2 from 'vue-sweetalert2'
import ApiService from './services/api.service'
import { TokenService } from './services/storage.service'

// CSS IMPORT
import '@/permission'
import 'buefy/dist/buefy.css'
import '../src/assets/css/main.css'
import '@mdi/font/css/materialdesignicons.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import "shepherd.js/dist/css/shepherd.css";


// CSS FRAMEWORK
Vue.use(Buefy, {
  defaultIconPack: 'mdi'
})
Vue.use(VueSweetalert2)
Vue.use(GmapVue, {
  load: {
    key: process.env.VUE_APP_KEY_MAPS,
    libraries: 'places'
  },
  autoBindAllEvents: false,
  installComponents: true,
  dynamicLoad: false,
})
Vue.use(Geocoder, {
  defaultCountryCode  :null,
  defaultLanguage     :null,
  defaultMode         :'lat-lng',
  googleMapsApiKey    :process.env.VUE_APP_KEY_MAPS
})
Vue.use(VCalendar, {
  componentPrefix: 'vc',
});
Vue.prototype.$moment = moment

Vue.use({
  install: function (Vue) {
    Vue.prototype.$sanitize = DOMPurify.sanitize
  }
})

let handleOutsideClick
Vue.directive('closable', {
  bind (el, binding, vnode) {
    handleOutsideClick = (e) => {
      e.stopPropagation()
      const { handler, exclude } = binding.value
      let clickedOnExcludedEl = false
      exclude.forEach(refName => {
        if (!clickedOnExcludedEl) {
          const excludedEl = vnode.context.$refs[refName]
          clickedOnExcludedEl = excludedEl.contains(e.target)
        }
      })
      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        vnode.context[handler]()
      }
    }
    document.addEventListener('click', handleOutsideClick)
    document.addEventListener('touchstart', handleOutsideClick)
  },

  unbind () {
    document.removeEventListener('click', handleOutsideClick)
    document.removeEventListener('touchstart', handleOutsideClick)
  }
});

let handleOutsideClick2
Vue.directive('closable2', {
  bind (el, binding, vnode) {
    handleOutsideClick2 = (e) => {
      e.stopPropagation()
      const { handler, exclude } = binding.value
      let clickedOnExcludedEl = false
      exclude.forEach(refName => {
        if (!clickedOnExcludedEl) {
          const excludedEl = vnode.context.$refs[refName]
          clickedOnExcludedEl = excludedEl.contains(e.target)
        }
      })
      if (!el.contains(e.target) && !clickedOnExcludedEl) {
        vnode.context[handler]()
      }
    }
    document.addEventListener('click', handleOutsideClick2)
    document.addEventListener('touchstart', handleOutsideClick2)
  },

  unbind () {
    document.removeEventListener('click', handleOutsideClick2)
    document.removeEventListener('touchstart', handleOutsideClick2)
  }
});

// API URL & HEADER
ApiService.init(process.env.VUE_APP_ROOT_API)
if (TokenService.getToken()) {
  ApiService.setHeader()
}

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
