const DATA_USER = 'b2b'
const TOKEN_KEY = 'attribute'
const EMAIL_TEMP = 'temp-email'
const REFRESH_TOKEN_KEY = 'refresh-token'
const FLAG_POPUP_NEWUSER = 'has-popup-newuser'
const FLAG_CHANGE_PASSWORD = 'has-default-pass'

import { encryptData } from "@/utils/index"

const TokenService = {
    saveToken (accessToken) {
        localStorage.setItem(TOKEN_KEY, accessToken)
    },
    getToken () {
        return localStorage.getItem(TOKEN_KEY)
    },
    removeToken () {
        localStorage.removeItem(TOKEN_KEY)
    },
    getRefreshToken () {
        return localStorage.getItem(REFRESH_TOKEN_KEY)
    },
    saveRefreshToken (refreshToken) {
        localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
    },
    removeRefreshToken () {
        localStorage.removeItem(REFRESH_TOKEN_KEY)
    }
}

const DataUser = {
    saveDataUser (payload) {
        const enkripsiData = encryptData(payload)
        localStorage.setItem(DATA_USER, enkripsiData)
    },
    removeDataUser () {
        localStorage.removeItem(DATA_USER)
    },
}

const DefaultPassword = {
    saveFlagPass (payload) {
        localStorage.setItem(FLAG_CHANGE_PASSWORD, payload)
    },
    getFlagPass () {
        return localStorage.getItem(FLAG_CHANGE_PASSWORD)
    },
    removeFlagPass () {
        localStorage.removeItem(FLAG_CHANGE_PASSWORD)
    }
}

const IsPopupNewuser = {
    saveFlag (payload) {
        localStorage.setItem(FLAG_POPUP_NEWUSER, payload)
    },
    getFlag () {
        return localStorage.getItem(FLAG_POPUP_NEWUSER)
    },
    removeFlag () {
        localStorage.removeItem(FLAG_POPUP_NEWUSER)
    }
}

const emailTemporary = {
    saveFlag (payload) {
        localStorage.setItem(EMAIL_TEMP, payload)
    },
    getFlag () {
        return localStorage.getItem(EMAIL_TEMP)
    },
    removeFlag () {
        localStorage.removeItem(EMAIL_TEMP)
    }
}

const clearStorage = {
    removeAllStorage () {
        localStorage.clear()
    }
}

export {
    DefaultPassword,
    emailTemporary,
    IsPopupNewuser,
    TokenService,
    clearStorage,
    DataUser
}