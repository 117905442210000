import ApiService from './api.service'

class ResponseError extends Error {
    constructor(errorCode, message) {
        super(message)
        this.message = message
        this.errorCode = errorCode
    }
}

const branchService = {
    getBranch: async function(payload) {
        try {
            const requestData = {
                method: 'GET',
                url: `${process.env.VUE_APP_BRANCHES}`,
                params: payload,
            }
            const result = await ApiService.customRequest(requestData)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    picBranchApi: async function(payload) {
        try {
            const requestData = {
                method: 'GET',
                url: `${process.env.VUE_APP_PIC_BRANCH}`,
                params: payload,
            }
            const result = await ApiService.customRequest(requestData)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    registerPicBranch: async function(payload) {
        try {
            const url = process.env.VUE_APP_USERS_V2
            const data = {}

            if (payload !== null) {
                data.role_id = process.env.VUE_APP_ROLE_ID;
                data.phone = payload.phone;
                data.name = payload.name;
                data.branch_ids = payload.branch_ids;

                if (payload.email !== null) {
                    data.email = payload.email;
                }
            }

            const result = await ApiService.post(url, data)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    summaryBranchPicApi: async function() {
        try {
            const url = process.env.VUE_APP_SUMMARY_USER_BRANCH_STORE
            const result = await ApiService.get(url)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    storeListBranchApi: async function() {
        try {
            const url = process.env.VUE_APP_STORE_LIST_BRANCH
            const result = await ApiService.get(url)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    deletePicBranchApi: async function(path) {
        try {
            const url = `${process.env.VUE_APP_DELETE_PIC_BRANCH}/${path}`
            const result = await ApiService.delete(url)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    deletePicFromBranchService: async function(params) {
        try {
            const url = `${process.env.VUE_APP_PIC_BRANCH}/${params.pic_id}/${params.branch_id}`
            const result = await ApiService.delete(url)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    }
}

export default branchService

export {
    branchService,
    ResponseError
}