import { AES, enc } from 'crypto-js'

const KEY_LOCAL_STORAGE = process.env.VUE_APP_SECRET_KEY

export function encryptData(data) {
    const encryptDatas = AES.encrypt(JSON.stringify(data), KEY_LOCAL_STORAGE).toString()
    return encryptDatas
}

export function decryptData(data) {
    const decryptDatas = AES.decrypt(data, KEY_LOCAL_STORAGE).toString(enc.Utf8)
    return decryptDatas
}

export function formatCurrency(value) {
    const result = value.toLocaleString('id-ID', {
        style: 'currency',
        currency: 'IDR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    return result
}

export function formatLongDate(dateTimeString) {
    if (!dateTimeString) {
        return '';
    }

    const months = [
        'Januari',
        'Februari',
        'Maret',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Agustus',
        'September',
        'Oktober',
        'November',
        'Desember'
    ];

    const dateTimeParts = dateTimeString.split(' ');

    if (dateTimeParts.length < 1) {
        return '';
    }

    const [date, time] = dateTimeParts;
    const [year, month, day] = date.split('-');
    const formattedMonth = months[parseInt(month) - 1];

    if (time) {
        const [hour, minutes, seconds] = time.split(':');
            return `${parseInt(day)} ${formattedMonth} ${year} ${hour}:${minutes}:${seconds}`;
        } else {
            return `${parseInt(day)} ${formattedMonth} ${year}`;
    }
}

export function convertDate(date) {
    let dateParameter = new Date(date),
    mnth = ("0" + (dateParameter.getMonth() + 1)).slice(-2),
    day = ("0" + dateParameter.getDate()).slice(-2);
    return [dateParameter.getFullYear(), mnth, day].join("-");
}


export function timeAgo(current, previous) {
    if (!current && !previous) {
        return ''
    }

    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    const msPerMonth = msPerDay * 30;
    const msPerYear = msPerDay * 365;

    const elapsed = current - previous;

    if (elapsed < msPerMinute) {
        return Math.round(elapsed / 1000) + ' detik lalu';
    } else if (elapsed < msPerHour) {
        return Math.round(elapsed / msPerMinute) + ' menit lalu';
    } else if (elapsed < msPerDay) {
        return Math.round(elapsed / msPerHour) + ' jam lalu';
    } else if (elapsed < msPerMonth) {
        return Math.round(elapsed / msPerDay) + ' hari lalu';
    } else if (elapsed < msPerYear) {
        return Math.round(elapsed / msPerMonth) + ' bulan lalu';
    } else {
        return Math.round(elapsed / msPerYear) + ' tahun lalu';
    }
}

export function nameRoute(tag) {
    if (!tag) {
        return false
    }

    let result
    switch (tag) {
        case 'offer-detail':
        case 'survey-detail':
            result = 'detailProject'
        break;

        case 'project-main-detail':
            result = 'DetailMainProject'
        break;

        case 'list-invoice':
            result = 'TagihanIndex'
        break;

        default:
            break;
    }
    return result
}

export function limitCharacter(text, limit) {
    if (text.length <= limit) {
      return text;
    } else {
      return text.slice(0, limit) + '...';
    }
}

export function groupDataImagePdf (arrayData, parameters) {
    const groupedObjects = []

    let currentIndex = 0;

    // first group in page 1 a limit of 3 element
    const firstGroup = arrayData.slice(currentIndex, currentIndex + parameters.firstGroupSize)
    if (firstGroup.length > 0) {
        groupedObjects.push({ groupedNumber: 1, elements: firstGroup })
        currentIndex += parameters.firstGroupSize
    }

    // Subsequent groups with a limit of 10 elements in page 2,3,4
    while (currentIndex + parameters.otherGroupSize < arrayData.length) {
        const otherGroup = arrayData.slice(currentIndex, currentIndex + parameters.otherGroupSize)
        groupedObjects.push({ groupedNumber: groupedObjects.length + 1, elements: otherGroup })
        currentIndex += parameters.otherGroupSize
    }

    // Last group with a limit of 5 elements
    const lastGroup = arrayData.slice(currentIndex, currentIndex + parameters.lastGroupSize)
    if (lastGroup.length > 0) {
        groupedObjects.push({ groupNumber: groupedObjects.length + 1, elements: lastGroup })
    }

    return groupedObjects
}

export function checkDateHoliday (date, arrHoliday) {
    for (let i = 0; i < arrHoliday.length; i++) {
        const holiday = arrHoliday[i];

        const startHoliday = new Date(holiday.start);
        const endHoliday = new Date(holiday.end);

        if (date >= startHoliday && date <= endHoliday) {
            return true;
        }
    }

    return false;
}

/**
    convert number to string for nominal

    * @param number
    * @return
*/
export function terbilang(angka) {
    const bilangan = ["", "Satu", "Dua", "Tiga", "Empat", "Lima", "Enam", "Tujuh", "Delapan", "Sembilan", "Sepuluh", "Sebelas"];

    function konversi(angka) {
        if (angka < 12) {
            return bilangan[angka];
        } else if (angka < 20) {
            return bilangan[angka - 10] + " Belas";
        } else if (angka < 100) {
            return bilangan[Math.floor(angka / 10)] + " Puluh " + bilangan[angka % 10];
        } else if (angka < 200) {
            return "Seratus " + konversi(angka - 100);
        } else if (angka < 1000) {
            return bilangan[Math.floor(angka / 100)] + " Ratus " + konversi(angka % 100);
        } else if (angka < 2000) {
            return "Seribu " + konversi(angka - 1000);
        } else if (angka < 1000000) {
            return konversi(Math.floor(angka / 1000)) + " Ribu " + konversi(angka % 1000);
        } else if (angka < 1000000000) {
            return konversi(Math.floor(angka / 1000000)) + " Juta " + konversi(angka % 1000000);
        } else if (angka < 1000000000000) {
            return konversi(Math.floor(angka / 1000000000)) + " Miliar " + konversi(angka % 1000000000);
        } else if (angka < 1000000000000000) {
            return konversi(Math.floor(angka / 1000000000000)) + " Triliun " + konversi(angka % 1000000000000);
        }
    }

    return konversi(angka) + " Rupiah";
}