const getIdPic = state => state.pic_id
const getAddress = state => state.address
const getNamePic = state => state.name_pic
const getFileSpk = state => state.file_spk
const getFileSik = state => state.file_sik
const getPhonePic = state => state.phone_pic
const getDateSesi = state => state.date_sesi
const getBranchId = state => state.branch_id
const getPicBranch = state => state.pic_branch
const getNameBranch = state => state.nameBranch
const getAddressAll = state => state.address_all
const getLimitPages = state => state.limit_page
const getTotalPages = state => state.total_pages
const getListSurvey = state => state.list_survey
const getListOffering = state => state.list_offer
const getDateHoliday = state => state.date_holiday
const getCurrentPage = state => state.current_page
const getAddressNote = state => state.address_note
const getNoteConfirm = state => state.note_confirm
const getPhotoProblem = state => state.photo_problem
const getSurveyDetail = state => state.detailsSurvey
const getOfferNumber = state => state.offering_number
const getStatusOffering = state => state.status_offer
const getAddressLatest = state => state.address_latest
const getStatusFilterOffer = state => state.tab_status_offer
const getDateConfirmOffer = state => state.date_confirm_offer
const getStatusFilterSurvey = state => state.tab_status_survey
const getDetailProjectMain = state => state.detail_main_project
const getProjectDescription = state => state.project_description
const getDateHolidayProject = state => state.date_holiday_project
const getShowTourAttachment = state => state.tour_attachment_show
const getDurationPreparation = state => state.duration_preparation



// ERROR RESPONSES
const getResponseErrorCode = state => state.responseErrorCode
const getResponseErrorMessage = state => state.responseErrorMessage


export default {
    getIdPic,
    getFileSpk,
    getFileSik,
    getNamePic,
    getAddress,
    getPhonePic,
    getBranchId,
    getDateSesi,
    getPicBranch,
    getAddressAll,
    getNameBranch,
    getLimitPages,
    getTotalPages,
    getCurrentPage,
    getListSurvey,
    getAddressNote,
    getDateHoliday,
    getNoteConfirm,
    getOfferNumber,
    getPhotoProblem,
    getSurveyDetail,
    getListOffering,
    getAddressLatest,
    getStatusOffering,
    getDateConfirmOffer,
    getResponseErrorCode,
    getDetailProjectMain,
    getStatusFilterOffer,
    getStatusFilterSurvey,
    getShowTourAttachment,
    getDateHolidayProject,
    getProjectDescription,
    getDurationPreparation,
    getResponseErrorMessage
}