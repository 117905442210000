const setDetailUser = (state, value) => {
    if (value) {
        state.profile_picture  = value.image_url
        state.preview_image = value.image_url
        state.namePic = value.name
        state.emailPic = value.email
        state.noPic = value.phone
        state.rolePic = value.role_id
        state.can_add_address = value.vendor_can_add_address
    }
}

const setImageSelected = (state, value) => {
    state.profile_picture  = value
}

const setImagePreview = (state, value) => {
    state.preview_image = value
}

const setDeleteProfileImage = (state) => {
    state.profile_picture = null
    state.preview_image = null
}

const responseSuccess = (state, {statusCode, message }) => {
    state.httpCode = statusCode
    state.httpMessage = message
}

const responseError = (state, {statusCode, message}) => {
    state.httpCode = statusCode
    state.httpMessage = message
}

const defaultState = (state) => {
    state.profile_picture = null
    state.preview_image = null
    state.namePic = null
    state.emailPic = null
    state.noPic = null
    state.rolePic = null
    state.httpCode = 0,
    state.httpMessage = ''
}

export default {
    setDeleteProfileImage,
    setImageSelected,
    setImagePreview,
    responseSuccess,
    responseError,
    setDetailUser,
    defaultState
}