<template>
  <div class="app-wrapper">
    <div class="main-container">
      <NavbarComponent />
      <app-main />
      <FooterComponent />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { errorHandler } from "@/utils/error-handler";
import { IsPopupNewuser } from "../services/storage.service";
import { onSocketEvent, offSocketEvent } from "@/utils/websocket";

export default {
  name: "TheLayout",
  data() {
    return {
      vendorName: null,
      profileName: null,
      userId: null,
      flagFromForgotPassword: false,
    };
  },
  components: {
    AppMain: () => import("./components/AppMain.vue"),
    NavbarComponent: () => import("./components/Navbar.vue"),
    FooterComponent: () => import("./components/Footer.vue"),
  },
  computed: {
    ...mapGetters("dashboard", [
      "getUserInteracted",
      "getResponseErrorCode",
      "getResponseErrorMessage",
    ]),
  },
  methods: {
    playAudio() {
      const audio = new Audio(require("@/assets/notification_B2B.mp3"));
      audio.play();
    },
    reRender() {
      this.$forceUpdate();
    },
    dataFlagLocal() {
      const hasDefaultPass = IsPopupNewuser.getFlag();
      const data = JSON.parse(hasDefaultPass);

      this.flagFromForgotPassword = data === 1 ? true : false;
    },
    async getFlagForgotPass() {
      await this.$store.dispatch("user/detailProfile").then((response) => {
        this.profileName =
          response.data.code === 1 ? response.data.data.name : null;
        this.vendorName =
          response.data.code === 1 ? response.data.data.vendor_name : null;
        this.userId = response.data.code === 1 ? response.data.data.id : null;
      });

      if (this.flagFromForgotPassword) {
        const imagePath = require("../assets/img/illustrasi_login_change_password.svg");

        this.$swal({
          html: `
            <span>
              <p class="averta-bold is-size-4">Halo, ${this.profileName}!</p>
              <span>
                <small class="averta-reguler">Selamat datang di Dashboard Kanggo x ${this.vendorName}</small>
              </span>
            </span>
          `,
          imageUrl: imagePath,
          imageWidth: 500,
          imageHeight: 200,
          width: 600,
          height: 400,
          confirmButtonText: "Mulai",
          allowOutsideClick: false,
          customClass: {
            confirmButton: "swal-button-primary-single",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            IsPopupNewuser.removeFlag();
            this.flagFromForgotPassword = false;
            this.reRender();
            location.reload(true);
          }
        });
      } else {
        this.reRender();
      }
    },
    getNotifPreview() {
      this.$store
        .dispatch("dashboard/notificationPreview")
        .then(() => {})
        .catch((error) => {
          errorHandler(
            this.getResponseErrorCode,
            "Terjadi kesalahan saat memuat data."
          );
          throw new Error(error);
        });
    },
    socketNotifPreview() {
      const KEY_SOCKET_PREVIEW = process.env.VUE_APP_KEY_SOCKET_PREVIEW;
      onSocketEvent(KEY_SOCKET_PREVIEW, this.handleSocketPreview);
    },
    offSocketNotifPreview() {
      const KEY_SOCKET_PREVIEW = process.env.VUE_APP_KEY_SOCKET_PREVIEW;
      offSocketEvent(KEY_SOCKET_PREVIEW, this.handleSocketPreview);
    },
    handleSocketPreview(notification) {
      const userIdNotif = notification?.user_id;

      if (this.userId === userIdNotif) {
        this.$store.commit("dashboard/previewNotification", {
          unreadCount: notification.unread_count,
          notifPreview: notification.notifications,
        });
        if (this.getUserInteracted) {
          this.playAudio();
          this.$store.commit("dashboard/setUserInteracted", false);
        }
      } else {
        return;
      }
    },
    socketNotifAll() {
      const KEY_SOCKET_ALL = process.env.VUE_APP_KEY_SOCKET_ALL;
      onSocketEvent(KEY_SOCKET_ALL, this.handleSocketAll);
    },
    offSocketNotifAll() {
      const KEY_SOCKET_ALL = process.env.VUE_APP_KEY_SOCKET_ALL;
      offSocketEvent(KEY_SOCKET_ALL, this.handleSocketAll);
    },
    handleSocketAll(notification) {
      const userIdNotif = notification?.user_id;

      if (this.userId === userIdNotif) {
        const result = {
          current_page: notification.current_page,
          total_page: notification.total_page,
          data: notification.data,
        };
        this.$store.commit("dashboard/allNotification", result);
      } else {
        return;
      }
    },
  },
  mounted() {},
  created() {
    this.dataFlagLocal();
    this.socketNotifAll();
    this.getNotifPreview();
    this.getFlagForgotPass();
    this.socketNotifPreview();
  },
  beforeDestroy() {
    this.offSocketNotifAll();
    this.offSocketNotifPreview();
  },
};
</script>

<style lang="scss" scoped>
.app-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  background: #fafdff;
  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}
</style>
