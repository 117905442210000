import ApiService from '../services/api.service'
import { SnackbarProgrammatic as Snackbar } from 'buefy'
import { clearStorage } from '../services/storage.service'

export function errorHandler(code, messages) {
  if (!code && !messages) {
    return ''
  }

  const httpCode = code

  switch (httpCode) {
    case 400:
    case 500:
      Snackbar.open({
        message: messages,
        type: "is-danger",
        position: "is-top",
        indefinite: true,
      });
      break;

    case 401:
      Snackbar.open({
        message: messages,
        type: "is-danger",
        position: "is-top",
        indefinite: true,
        onAction: () => {
          ApiService.removeHeader()
          clearStorage.removeAllStorage()
          location.reload(true)
        }
      });
      break;

    default:
      break;
  }

}