import ApiService from './api.service'

class ResponseError extends Error {
    constructor(errorCode, message) {
        super(message)
        this.message = message
        this.errorCode = errorCode
    }
}

const dashboardService = {
    getSummary: async function() {
        try {
            const url = process.env.VUE_APP_SUMMARY_PROJECT
            const result = await ApiService.get(url)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    getApiListPerforma: async function(params) {
        try {
            const url = `${process.env.VUE_APP_SUMMARY_SURVEY_PERFORMA}?date=${params}`
            const result = await ApiService.get(url)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    getApiEventDateProjectSurvey: async function(params) {
        try {
            const url = `${process.env.VUE_APP_TIMELINE_SURVEY_PROJECT}?month=${params.month}&year=${params.year}`
            const result = await ApiService.get(url)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    getApiEventDateSurveyDetail: async function(params) {
        try {
            const url = `${process.env.VUE_APP_TIMELINE_SURVEY_PROJECT_DETAIL}?date=${params}`
            const result = await ApiService.get(url)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    getApiEventDateProject: async function(params) {
        try {
            const url = `${process.env.VUE_APP_TIMELINE_PROJECT}?month=${params.month}&year=${params.year}`
            const result = await ApiService.get(url)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    getNotificationPreview: async function() {
        try {
            const url = `${process.env.VUE_APP_NOTIF_PREVIEW}?limit=${process.env.VUE_APP_LIMIT_NOTIFICATION}`
            const result = await ApiService.get(url)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    getAllNotification: async function(payload) {
        try {
            const requestData = {
                method: 'GET',
                url: `${process.env.VUE_APP_NOTIF_ALL}`,
                params: payload,
            }
            const result = await ApiService.customRequest(requestData)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    markAsReadSingle: async function(path) {
        try {
            const url = `${process.env.VUE_APP_NOTIF_MARK_AS_READ}/${path}`
            const result = await ApiService.put(url)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    },
    markAsReadBulk: async function(params) {
        try {
            const url = `${process.env.VUE_APP_NOTIF_MARK_AS_READ}`
            const data = {
                type: params
            }
            const result = await ApiService.post(url, data)
            return result
        } catch (error) {
            throw new ResponseError(error.response.status, error.response.data)
        }
    }
}

export default dashboardService

export {
    dashboardService,
    ResponseError
}