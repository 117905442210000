const responsesError = (state, {errorCode, errorMessage}) => {
    state.responseErrorCode = errorCode
    state.responseError = errorMessage
}

const setUserInteracted = (state, value) => {
    state.user_interacted = value
}

const newProjectSurvey = (state) => {
    state.survey = {
        branch_id: null,
        pic_id: null,
        building_type_id: '',
        date_sesi: '',
        project_description: null,
        address_note: null
    }
}

const setDescription = (state, description) => {
    state.survey.project_description = description
}

const previewNotification = (state, {unreadCount, notifPreview}) => {
    state.unread_count = unreadCount
    state.notification_preview = notifPreview
}

const allNotification = (state, value) => {
    if (value) {
        state.current_page = value.current_page
        state.total_pages = value.total_page
        state.notification_all = value.data
    }
}

const setHeightNavbar = (state, value) => {
    if (value) {
        state.heightNavbar = value
    }
}

const defaultState = (state) => {
    state.responseError = ''
    state.responseErrorCode = 0
    state.summary = null
    state.unread_count = null
    state.notification_preview = []
    state.notification_all = []
    state.heightNavbar = null
    state.user_interacted = false
}



export default {
    defaultState,
    setDescription,
    responsesError,
    allNotification,
    setHeightNavbar,
    newProjectSurvey,
    setUserInteracted,
    previewNotification
}