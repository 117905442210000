import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
    bill_invoice: null,
    sub_status: [],
    responseErrorCode: 0,
    responseErrorMessage: '',
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}