import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const state = {
    branch_id: null,
    name_pic: null,
    phone_pic: null,
    pic_id: null,
    pic_branch: [],
    photo_problem: [],
    building_type_id: '',
    date_sesi: '',
    project_description: null,
    address_note: null,
    address: null,
    nameBranch: null,
    detailsSurvey: null,
    file_spk: null,
    file_sik: null,
    note_confirm: null,
    date_confirm_offer: null,
    offering_number: null,
    detail_main_project: null,
    duration_preparation: null,
    address_all: null,
    address_latest: null,
    list_survey: [],
    list_offer: [],
    status_offer: null,
    tab_status_survey: [],
    tab_status_offer: [],
    date_holiday: [],
    date_holiday_project: [],
    tour_attachment_show: 0,
    current_page: 1,
    total_pages: 0,
    limit_page: 10,
    responseErrorCode: 0,
    responseErrorMessage: '',
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}