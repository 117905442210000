const getLimitPages = state => state.limit_page
const getTotalPages = state => state.total_pages
const getUnreadCount = state => state.unread_count
const getCurrentPage = state => state.current_page
const getNavbarHeight = state => state.heightNavbar
const getUserInteracted = state => state.user_interacted
const getAllNotification = state => state.notification_all
const getNotificationPreview = state => state.notification_preview


// ERROR RESPONSES
const getResponseErrorMessage = state => state.responseError
const getResponseErrorCode = state => state.responseErrorCode


export default {
    getLimitPages,
    getTotalPages,
    getCurrentPage,
    getUnreadCount,
    getNavbarHeight,
    getUserInteracted,
    getAllNotification,
    getResponseErrorCode,
    getNotificationPreview,
    getResponseErrorMessage
}