import io from 'socket.io-client';

const URL_SOCKET = process.env.VUE_APP_SOCKET_URL
const socket =  io(URL_SOCKET, {
  withCredentials: true
})

export function onSocketEvent (key, callback) {
  return socket.on(key, callback)
}

export function offSocketEvent (key, callback) {
  return socket.off(key, callback)
}

export function removeAllEventSocket() {
  return socket.removeAllListeners();
}