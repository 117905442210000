import axios from 'axios'
import router from '@/router'
import ApiService from './api.service'
import {
  TokenService,
  clearStorage,
  DefaultPassword,
  IsPopupNewuser,
  emailTemporary
} from './storage.service'


class HttpStatus extends Error {
  constructor(code, message) {
    super(message)
    this.message = message
    this.statusCode = code
  }
}

const authenticationService = {

  login: async function(email, password) {

    try {
      const httpRequest = await axios({
        method: 'POST',
        url: process.env.VUE_APP_LOGIN_API,
        data: {
          email: email,
          password: password
        }
      })
      const response = httpRequest.data?.data

      if (response.is_password_default === 1) {
        emailTemporary.saveFlag(response.email)
      }

      DefaultPassword.saveFlagPass(response.is_password_default)
      IsPopupNewuser.saveFlag(response.is_password_default)
      TokenService.saveToken(response.token)
      ApiService.setHeader()

      router.push(router.history.current.query.redirect || '/')
      return true
    } catch (error) {
      throw new HttpStatus(error.response.status, error.response.data)
    }
  },
  forgotPassword: async function(email) {
    try {
      const url = process.env.VUE_APP_FORGOT_PASSWORD_API
      const payload = {
        email: email
      }
      const response = await ApiService.post(url, payload)
      return response
    } catch (error) {
      throw new HttpStatus(error.response.status, error.response.data)
    }
  },
  changePassword: async function(payload) {
    try {
      const url = process.env.VUE_APP_CHANGE_PASSWORD_API
      const data = {
        password: payload.password,
        repeat_password: payload.repeat_password
      }
      const result = await ApiService.post(url, data)
      DefaultPassword.saveFlagPass(0)
      emailTemporary.removeFlag()

      return result
    } catch (error) {
      throw new HttpStatus(error.response.status, error.response.data)
    }
  },
  changePasswordFromForgot: async function(payload) {
    try {
      const url = process.env.VUE_APP_CHANGE_PASSWORD_FORM_FORGOT_API
      const data = {
        password: payload.password,
        repeat_password: payload.repeat_password,
        token: payload.token
      }
      const result = await ApiService.post(url, data)
      return result
    } catch (error) {
      throw new HttpStatus(error.response.status, error.response.data)
    }
  },
  changePasswordFromProfile: async function(payload) {
    try {
      const url = process.env.VUE_APP_CHANGE_PASSWORD_PROFILE_API
      const data = {
        old_password: payload.oldPass,
        password: payload.newPass,
        repeat_password: payload.repeatPass
      }
      const result = await ApiService.post(url, data)
      return result
    } catch (error) {
      throw new HttpStatus(error.response.status, error.response.data)
    }
  },
  logout() {
    ApiService.removeHeader()
    clearStorage.removeAllStorage()
  },

}

export default authenticationService

export {
  authenticationService,
  HttpStatus
}

