// import router from '@/router'
import store from "@/store"
import {dashboardService, ResponseError} from "@/services/dashboard.service"

const summaryProject = async ({ commit }) => {
    try {
        const result = await dashboardService.getSummary()
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const summarySurveyPerforma = async ({ commit }, params) => {
    try {
        const result = await dashboardService.getApiListPerforma(params)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const eventDateProjectSurvey = async ({ commit }, params) => {
    try {
        const dataParams = {
            month: params.month,
            year: params.year
        }
        const result = await dashboardService.getApiEventDateProjectSurvey(dataParams)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const eventDateSurveyDetail = async ({ commit }, params) => {
    try {
        const result = await dashboardService.getApiEventDateSurveyDetail(params)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const eventDateProject = async ({ commit }, params) => {
    try {
        const result = await dashboardService.getApiEventDateProject(params)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const notificationPreview = async ({ commit }) => {
    try {
        const result = await dashboardService.getNotificationPreview()
        commit('previewNotification', {unreadCount: result.data?.data?.unread_count, notifPreview: result.data?.data?.notifications})
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const allNotification = async ({ commit }, page) => {
    try {
        const payload = {
            page: page,
            limit: store.getters['dashboard/getLimitPages']
        }
        const result = await dashboardService.getAllNotification(payload)
        commit('allNotification', result.data)
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const markAsReadSingle = async ({commit}, path) => {
    try {
        const result = await dashboardService.markAsReadSingle(path)
        commit('responsesError', {errorCode: result.status, errorMessage: result.data.message})
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const markAsReadBulk = async ({commit}, params) => {
    try {
        const result = await dashboardService.markAsReadBulk(params)
        commit('responsesError', {errorCode: result.status, errorMessage: result.data.message})
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}




export default {
    markAsReadBulk,
    summaryProject,
    allNotification,
    eventDateProject,
    markAsReadSingle,
    notificationPreview,
    eventDateSurveyDetail,
    summarySurveyPerforma,
    eventDateProjectSurvey,
}