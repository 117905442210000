import { billingService, ResponseError } from '@/services/billing.service'

const billOfList = async ({ commit }, payload) => {
    try {
        const result = await billingService.billOfListAPI(payload)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const billOfListAndFailed = async ({ commit }, payload) => {
    try {
        const result = await billingService.billOfListAndFailedAPI(payload)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const priceCalculation = async ({ commit }, payload) => {
    try {
        const result = await billingService.priceCalculationAPI(payload)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const payBill = async ({ commit }, payload) => {
    try {
        const result = await billingService.payBillAPI(payload)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const billingDetailList = async ({ commit }, payload) => {
    try {
        const result = await billingService.billingDetailListAPI(payload)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}


// ACTION FOR MUTATION
const resetAll = ({ commit }) => {
    commit('resetAll')
}

// END ACTION FOR MUTATION


export default {
    payBill,
    resetAll,
    billOfList,
    priceCalculation,
    billingDetailList,
    billOfListAndFailed
}