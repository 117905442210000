// import router from '@/router'
import { projectService, ResponseError } from '@/services/project.service'

const createSurveyProject = async ({ commit }, payload) => {
    try {
        const newObj = {};
        for (const key in payload) {
          if (payload[key] !== null) {
            newObj[key] = payload[key];
          }
        }

        const formData = new FormData()
        formData.append('branch_id', newObj.branch_id)
        formData.append('pic_id', newObj.pic_id)
        formData.append('date_sesi', newObj.date_sesi)
        if (newObj.description && newObj.description !== null) {
            formData.append('description', newObj.description)
          }
        if (newObj.address_reference && newObj.address_reference !== '') {
            formData.append('address_reference', newObj.address_reference)
          }
        for (let j = 0; j < newObj.images.length; j++) {
            formData.append('images', newObj.images[j].files)
        }

        const result = await projectService.createProjectSurvey(formData)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const rescheduleProjectSurvey = async ({ commit }, payload) => {
    try {
        const result = await projectService.rescheduleProjectSurveyApi(payload)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const cancelRescheduleProjectSurvey = async ({ commit }, params) => {
    try {
        const result = await projectService.cancelRescheduleProjectSurveyApi(params)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const deleteListRescheduleSurvey = async ({ commit }, params) => {
    try {
        const result = await projectService.deleteRescheduleProjectSurveyApi(params)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const listRescheduleSurvey = async ({ commit }) => {
    try {
        const result = await projectService.rescheduleListProjectSurveyApi()
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const listProjectSurvey = async ({ commit }, payload) => {
    try {
        const result = await projectService.listProjectSurveyApi(payload)
        commit('setListSurvey', result.data)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const listProjectOffer = async ({ commit }, payload) => {
    try {
        const result = await projectService.listProjectOfferApi(payload)
        commit('setListOffer', result.data)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const listProjectMain = async ({ commit }, payload) => {
    try {
        const result = await projectService.listProjectMainApi(payload)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const detailProjectMain = async ({ commit }, payload) => {
    try {
        const result = await projectService.detailProjectMainApi(payload)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const detailProjectSurvey = async ({ commit }, id) => {
    try {
        const result = await projectService.detailProjectSurveyApi(id)
        commit('setDetailSurvey', result.data.data)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const confirmOfferSurvey = async ({ commit }, payload) => {
    try {
        const result = await projectService.confirmOfferApi(payload)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const offerApprovedRejected = async ({ commit }, payload) => {
    try {
        const result = await projectService.offerApprovedRejectedAPI(payload)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const privateNote = async ({ commit }, payload) => {
    try {
        const result = await projectService.privateNoteSurveyApi(payload)
        commit('responsesError', {errorCode: result.status, errorMessage: result.data.message})
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const privateNoteMainProject = async ({ commit }, payload) => {
    try {
        const result = await projectService.privateNoteMainProjectApi(payload)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const cancelProjectSurvey = async ({ commit }, params) => {
    try {
        const result = await projectService.cancelProjectSurveyApi(params)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const confirmBranchProject = async ({ commit }, payload) => {
    try {
        const result = await projectService.confirmBranchProjectApi(payload)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

// CHECKING IMAGE SURVEY
const checkAllImageArea = async ({ commit }, data) => {
    try {
        let payload

        if (typeof data.data === 'number') {
            payload = {
                item_component_id: data.data,
                checked: data.checked === true ? 1 : 0
            }
        } else {
            payload = {
                item_component_id: data.data?.itemsId,
                checked: data.data?.checked === true ? 1 : 0
            }
        }

        const result = await projectService.checklistPerItem(payload)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const checkImageAll = async ({ commit }, data) => {
    try {
        const result = await projectService.checklistItemAll(data)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}
// END CHECKING IMAGE SURVEY

// TABS PROJECT
const getTabsParentProject = async ({ commit }) => {
    try {
        const result = await projectService.tabsParentProject()
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}
const getTabsChildProject = async ({ commit }) => {
    try {
        const result = await projectService.tabsChildProject()
        commit('setTabFilterSurvey', result.data?.data)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}
const getTabsChildOffer = async ({ commit }) => {
    try {
        const result = await projectService.tabsChildOffer()
        commit('setTabFilterOffer', result.data?.data)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}
const getTabsInProgressProject = async ({ commit }) => {
    try {
        const result = await projectService.inProgressTabsApi()
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}
const getTabsDoneProject = async ({ commit }) => {
    try {
        const result = await projectService.projectDoneTabsFilterApi()
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}
const getTabsTagihanProject = async ({ commit }) => {
    try {
        const result = await projectService.projectTagihanTabsFilterApi()
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}
// END TABS PROJECT

// Maps
const getMapsAddress = async({ commit }, data) => {
    try {
        const result = await projectService.addressMaps(data)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

// Add New Branch
const addBranchWithMaps = async({ commit }, data) => {
    try {
        const result = await projectService.addBranch(data)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

// Get Holiday Date
const getHolidayDate = async({ commit }, data) => {
    try {
        const result = await projectService.holidayDate(data)
        commit('setDateHoliday', result.data?.data)
        return true;
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

/**
    get data from services module project.service.js (onPrintFileApi)
    for offering and invoice preview

 * @data surveyId
 * @return
 */
const getPrintData = async({ commit }, data) => {
    try {
        const result = await projectService.onPrintFileApi(data)
        return result;
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

// FOR SET STATE IN MUTATION
const resetState = ({ commit }) => {
    commit('defaultState')
}

const imagePhotoProblem = ({ commit }, data) => {
    commit('setPhotoProblem', data)
}

const spliceImageProblem = ({ commit }, data) => {
    commit('setSpliceImageProblem', data)
}

const resetAll = ({ commit }) => {
    commit('resetAll')
}

// END SET STATE IN MUTATION

export default {
    resetAll,
    resetState,
    privateNote,
    getPrintData,
    checkImageAll,
    getMapsAddress,
    getHolidayDate,
    listProjectMain,
    listProjectOffer,
    addBranchWithMaps,
    checkAllImageArea,
    detailProjectMain,
    getTabsChildOffer,
    listProjectSurvey,
    imagePhotoProblem,
    spliceImageProblem,
    getTabsDoneProject,
    confirmOfferSurvey,
    cancelProjectSurvey,
    detailProjectSurvey,
    createSurveyProject,
    getTabsChildProject,
    getTabsParentProject,
    listRescheduleSurvey,
    confirmBranchProject,
    offerApprovedRejected,
    getTabsTagihanProject,
    privateNoteMainProject,
    rescheduleProjectSurvey,
    getTabsInProgressProject,
    deleteListRescheduleSurvey,
    cancelRescheduleProjectSurvey
}