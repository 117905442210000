import {branchService, ResponseError } from '@/services/branch.service'

const branchAll = async ({ commit }, payload) => {
    try {
        const result = await branchService.getBranch(payload)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const picBranch = async ({ commit }, payload) => {
    try {
        const result = await branchService.picBranchApi(payload)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const registerPicBranch = async ({ commit }, payload) => {
    try {
        const data = {
            role_id: process.env.VUE_APP_ROLE_ID,
            phone: payload.phone,
            name: payload.name,
            email: payload.email,
            branch_ids: payload.branch_ids
        }
        const result = await branchService.registerPicBranch(data)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const summaryBranchPic = async ({ commit }) => {
    try {
        const result = await branchService.summaryBranchPicApi()
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const storeListBranch = async ({ commit }) => {
    try {
        const result = await branchService.storeListBranchApi()
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const deletePicBranch = async ({ commit }, path) => {
    try {
        const result = await branchService.deletePicBranchApi(path)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

const deletePicFromBranch = async ({ commit }, params) => {
    try {
        const result = await branchService.deletePicFromBranchService(params)
        return result
    } catch (error) {
        if (error instanceof ResponseError) {
            commit('responsesError', {errorCode: error.errorCode, errorMessage: error.message.message})
        }
        return false
    }
}

// ACTION FOR MUTATION
const resetAll = ({ commit }) => {
    commit('defaultState')
}





export default {
    resetAll,
    branchAll,
    picBranch,
    storeListBranch,
    deletePicBranch,
    summaryBranchPic,
    registerPicBranch,
    deletePicFromBranch
}